@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.infoBox {
  -webkit-box-shadow: 3px 4px 22px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 3px 4px 22px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 4px 22px 1px rgba(0, 0, 0, 0.3);
  min-height: 200px;
}
.fa-lg {
  color: #e01e50;
}

.rdrCalendarWrapper>*{
  font-weight: bold !important;
}
@media (max-width: 768px) {
  .rdrMonth{
    width:auto !important;
  }
  .iconTrash{
    position: absolute;
        left: 80%;
        bottom: 50px;
    }
    .mobRel{
      position:relative;
    }
    .react-confirm-alert-button-group > button {
      width: 100%;font-size: 24px;
    }
}





/*
.timeInfoBlock {
  max-height: 300px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
*/